import Firebase from "firebase/compat/app";

/* commented due to large bundle size */
// import "firebase/compat/auth";
// import "firebase/compat/firestore";
import "firebase/compat/remote-config";

import firebaseConfig from "@helpers/config";

const config = {
    apiKey: firebaseConfig.FIREBASE_APIKEY,
    authDomain: firebaseConfig.FIREBASE_AUTHDOMAIN,
    databaseURL: firebaseConfig.FIREBASE_DATABASEURL,
    projectId: firebaseConfig.FIREBASE_PROJECTID,
    storageBucket: firebaseConfig.FIREBASE_STORAGEBUCKET,
    messagingSenderId: firebaseConfig.FIREBASE_MESSAGINGSENDERID,
    appId: firebaseConfig.FIREBASE_APPID,
    measurementId: firebaseConfig.FIREBASE_MEASUREMENTID,
};

if (!Firebase.apps.length) {
    Firebase.initializeApp(config);
}

export default Firebase;
export { default as rcConfig } from "./rcConfig";
export * from "./remote";
