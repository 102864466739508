import React, { createContext, useContext, useState, ReactNode } from "react";

interface PrerequisitesContextType {
    prerequisites: any | null;
    setPrerequisites: (prerequisites: any) => void;
}

export const PrerequisitesContext = createContext<PrerequisitesContextType>({
    prerequisites: null,
    setPrerequisites: () => {},
});

export const PrerequisitesProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [prerequisites, setPrerequisites] = useState<any | null>(null);

    return (
        <PrerequisitesContext.Provider
            value={{ prerequisites, setPrerequisites }}
        >
            {children}
        </PrerequisitesContext.Provider>
    );
};

// Custom hook to access the prerequisites context
export const usePrerequisites = () => useContext(PrerequisitesContext);
