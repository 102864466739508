import { Capacitor } from "@capacitor/core";
import { getSafariVersion, getChromeVersion } from "./utils";
import { isChrome, isSafari } from "./isSome";

export const isDVHSupported = () => {
    try {
        return CSS.supports("height", "100dvh");
    } catch {
        if (isSafari() && getSafariVersion() < 15.3) {
            return false;
        }
        if (isChrome() && getChromeVersion() < 107) {
            return false;
        }
        return true;
    }
};

export const isServiceWorkerSupported = (): boolean => {
    if (Capacitor.getPlatform() == "ios") return false;
    return true;
};

export const overwriteDVH = (length = 100) => {
    if (isDVHSupported()) {
        return `${length}dvh`;
    }
    if (Capacitor.getPlatform() == "web") {
        return typeof window != "undefined" && window?.innerHeight
            ? `${window?.innerHeight}px`
            : `${length}vh`;
    }
    return `${length}vh`;
};

export const overwriteTailwindDVH = () => {
    // can use template literal here, otherwise tailwind compiler will not include this custom classes
    const tailwindHight = ["h-[100dvh]", "h-[100vh]"];
    if (isDVHSupported()) {
        return tailwindHight[0];
    }
    return tailwindHight[1];
};

export const overwriteSVH = (length = 100) => {
    if (isDVHSupported()) {
        return `${length}svh`;
    }
    if (Capacitor.getPlatform() == "web") {
        return typeof window != "undefined" && window?.innerHeight
            ? `${window?.innerHeight}px`
            : `${length}vh`;
    }
    return `${length}vh`;
};

export const overwriteTailwindSVH = () => {
    // can use template literal here, otherwise tailwind compiler will not include this custom classes
    const tailwindHight = ["h-[100svh]", "h-[100vh]"];
    if (isDVHSupported()) {
        return tailwindHight[0];
    }
    return tailwindHight[1];
};
