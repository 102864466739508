export default {
    FIREBASE_APIKEY: process.env.NEXT_PUBLIC_FIREBASE_APIKEY || "",
    FIREBASE_AUTHDOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN || "",
    FIREBASE_DATABASEURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASEURL || "",
    FIREBASE_PROJECTID: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID || "",
    FIREBASE_STORAGEBUCKET:
        process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET || "",
    FIREBASE_MESSAGINGSENDERID:
        process.env.NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID || "",
    FIREBASE_APPID: process.env.NEXT_PUBLIC_FIREBASE_APPID || "",
    FIREBASE_MEASUREMENTID:
        process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENTID || "",
    FIREBASE_VAPID_KEY: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY || "",
};
