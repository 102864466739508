import { gql, useMutation } from "@apollo/client";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import React, { useEffect } from "react";
// import MathaiNotification from "src/plugins/MathaiNotification";
import { getStudentFromStorage } from "./migrateLogin";
// import { getMessaging, getToken } from "firebase/messaging";
// import firebaseConfig from "@helpers/config";
// import { isApp, isISOApp, isNativeApp } from "@utils/helpers";
import {
    onNotificationListener,
    requestNotificationPermission as requestNotificationPermissionWeb,
} from "src/plugins/webNotification";
import { Capacitor } from "@capacitor/core";
import { isIOS } from "@utils/helpers";

export const UPDATE_USER_NOTIFICATION_TOKEN = gql`
    mutation updateUserEmailByMobile(
        $mobile: String!
        $token: String!
        $platform: String!
    ) {
        update_personalized_learning_user(
            where: { mobile: { _eq: $mobile } }
            _set: { notification_token: $token, last_login_platform: $platform }
        ) {
            returning {
                id
                mobile
                notification_token
                last_login_platform
            }
        }
    }
`;

// const showNotification = async (data: any) => {
//     console.info("Showing notification", data);
//     await MathaiNotification.showNotification({
//         heading: "MathAI coach is starting..",
//         subTitle: "20/100",
//         progress: 20,
//         id: parseInt("1") as any,
//         layoutName: "custom_notification",
//         soundName: "sound_1",
//         ...(data ?? {}),
//     });
// };

// export const addNotificationListener = () => {
//     FirebaseMessaging.addListener("notificationReceived", async (message) => {
//         const notification = message?.notification;
//         const { data, ...rest } = notification;

//         showNotification({
//             ...(data ?? {}),
//             ...rest,
//         });
//     });
// };

// export const requestNotificationPermission = async (
//     callback?: (token: string) => void,
// ) => {
//     FirebaseMessaging.requestPermissions().then(
//         function (value) {
//             if (value.receive == "granted") {
//                 FirebaseMessaging.getToken().then(
//                     async function (value) {
//                         addNotificationListener();
//                         if (callback) {
//                             callback(value?.token ?? "");
//                         }
//                     },
//                     function (error) {
//                         console.error(JSON.stringify(error));
//                     },
//                 );
//             }
//         },
//         function (error) {
//             console.error(JSON.stringify(error));
//         },
//     );
// };

const NotificationWrapper = ({ children }: { children: React.ReactNode }) => {
    const [
        updateUserNotificationToken,
        { loading: loadingUserEmail, error: errorUserEmail, data: userEmail },
    ] = useMutation(UPDATE_USER_NOTIFICATION_TOKEN);

    useEffect(() => {
        if (isIOS()) return;
        const student = JSON.parse(getStudentFromStorage() || "{}");

        // if (isISOApp() || isApp() || isNativeApp()) {
        //     FirebaseMessaging.checkPermissions().then(
        //         function (value) {
        //             if (value.receive == "prompt") {
        //                 requestNotificationPermission(async (token) => {
        //                     if (student?.mobile) {
        //                         await updateUserNotificationToken({
        //                             variables: {
        //                                 mobile: student?.mobile,
        //                                 token: token,
        //                             },
        //                         });
        //                     }
        //                 });
        //             } else if (value.receive == "granted") {
        //                 FirebaseMessaging.getToken().then(
        //                     async function (value) {
        //                         console.info(
        //                             "Firebase Token Assigned",
        //                             value,
        //                             student,
        //                         );
        //                         addNotificationListener();
        //                         if (student?.id) {
        //                             await updateUserNotificationToken({
        //                                 variables: {
        //                                     mobile: student?.mobile,
        //                                     token: value?.token ?? "",
        //                                 },
        //                             });
        //                         }
        //                     },
        //                     function (error) {
        //                         console.error(JSON.stringify(error));
        //                     },
        //                 );
        //             }
        //         },
        //         function (error: any) {
        //             console.log(JSON.stringify(error));
        //         },
        //     );
        // } else {
        const setupNotifications = async () => {
            try {
                const token = await requestNotificationPermissionWeb();

                if (student?.mobile && token) {
                    await updateUserNotificationToken({
                        variables: {
                            mobile: student?.mobile,
                            token: token,
                            platform: Capacitor.getPlatform(),
                        },
                    });
                }

                if (token) {
                    console.log(
                        "User granted permission for notifications!",
                        token,
                    );
                }
            } catch (error) {
                console.log("here in error", error);
            }
        };
        setupNotifications();

        onNotificationListener().then((payload: any) => {
            console.log("Notification payload", payload);
        });
        // }
    }, []);
    return <>{children}</>;
};

export default NotificationWrapper;
