/* eslint-disable no-useless-escape */
import { useMutation } from "@apollo/client";
import { classNameOptions, profileTypeOptions } from "./constants";
import { INSERT_LOG } from "@modules/shared/graphql";
import { getStudentFromStorage } from "@helpers/migrateLogin";

const getBlanks = (text: any, index: number, parrentBlankIndex = 1) => {
    const parts: any[] = [];
    let blankIndex = parrentBlankIndex;
    if (!text.includes("()")) {
        parts.push({ type: "latex_span", val: text });
        return { parts, index };
    }
    const bool = true;
    while (bool) {
        const fibIndex = text.indexOf("()");
        if (fibIndex === -1) {
            parts.push({
                type: "latex_span",
                val: text.replace(/#neq/g, "\\neq"),
            });
            break;
        }
        parts.push({
            type: "latex_span",
            val: text.substring(0, fibIndex).replace(/#neq/g, "\\neq"),
        });
        index += 1;
        parts.push({
            type: "fib",
            val: index,
            blankIndex,
        });
        blankIndex += 1;
        text = text.substring(fibIndex + 2).replace(/#neq/g, "\\neq");
    }
    return { parts, index, blankIndex };
};

const getImages = (text: any, index: number) => {
    let parts: any[] = [];
    let bCount = index;
    if (!text.includes("<image")) {
        const prs = getBlanks(text, bCount);
        bCount = prs.index;
        parts = parts.concat(prs.parts);
        return { parts, index };
    }
    const bool = true;
    while (bool) {
        const imgIndex = text.indexOf("<image=");
        if (imgIndex === -1) {
            const prs = getBlanks(text, bCount);
            bCount = prs.index;
            parts = parts.concat(prs.parts);
            break;
        }
        const endIndex = text.indexOf('">');
        const prs = getBlanks(text.substring(0, imgIndex), bCount);
        bCount = prs.index;
        parts = parts.concat(prs.parts);
        parts.push({
            type: "img",
            val: text.substring(imgIndex + 8, endIndex),
        });
        text = text.substring(endIndex + 2);
    }
    return { parts, index };
};

export const getParts = (text: string, ParentblankIndex = 1): any[] => {
    const parts: any[] = [];
    if (!text) {
        return parts;
    }
    let txt = text.trim();
    txt = txt.replace("< image", "<image");
    txt = txt.replace("<image =", "<image=");
    txt = txt.replace("<image = ", "<image=");
    txt = txt.replace("<image= ", "<image=");
    txt = txt.replace('" >', '">');
    txt = txt.replace('="', '="');
    txt = txt.replace('">', '">');
    txt = txt.replace("'>", '">');
    txt = txt.replace(/\n/g, "\\n");
    let bCount = 0;

    // hack for not splitting at \neq
    txt = txt.replace("\\neq", "#neq");
    const splitted = txt.split("\\n");
    let blankIndex = ParentblankIndex;
    for (const splt of splitted) {
        const slptd = splt.split("\\n");
        for (let sp of slptd) {
            sp = sp.trim();
            if (sp === "") {
                parts.push({
                    type: "new_line",
                    val: "",
                });
            } else if (sp.includes("<image")) {
                const prs = getImages(sp, bCount);
                bCount = prs.index;
                parts.push({
                    type: "parts",
                    val: prs.parts,
                });
            } else if (sp.includes("\\dfrac") && sp.includes("()")) {
                // const sp = "a = $$\\dfrac{1+()}{()}$$ + 1";
                const split1 = sp.split(/(\$\$.*?\$\$)/);
                const mainParts = [];
                for (let i = 0; i < split1.length; i++) {
                    if (
                        split1[i].split(/\\(dfrac)/).length > 1 &&
                        split1[i].includes("()")
                    ) {
                        const st1 = split1[i] + split1[i + 1];
                        const arr = st1.match(/\{[^\}]*\}/g);
                        const blankBlock = [];

                        for (const ai of arr) {
                            const some = ai.slice(1, -1);
                            const len =
                                (some.split("()") &&
                                    some.split("()")?.length - 1) ||
                                0;
                            const res = getParts(some, blankIndex);
                            blankIndex += len;
                            blankBlock.push({ blankParts: res });
                        }
                        mainParts.push({
                            type: "dfrac",
                            blankBlock,
                        });
                        const split2 = st1.split(/\{[^\}]*\}/g);
                        if (split2[split2.length - 1] !== "") {
                            mainParts.push({
                                type: "latex_div",
                                val: split2[split2.length - 1],
                            });
                        }
                        i = i + 2;
                    } else {
                        mainParts.push({
                            type: "latex_div",
                            val: `${split1[i]} $$\\:$$`,
                        });
                    }
                }
                parts.push({
                    type: "dfrac",
                    subParts: mainParts,
                });
            } else if (sp.includes("()")) {
                const prs = getBlanks(sp, bCount, blankIndex);
                bCount = prs.index;
                parts.push({
                    type: "parts",
                    val: prs.parts,
                });
                blankIndex = prs.blankIndex;
            } else {
                parts.push({
                    type: "latex_div",
                    val: sp.replace(/#neq/g, "\\neq"),
                });
            }
        }
    }
    return parts;
};

export const getClassNameValue = (class_name: string) => {
    if (!class_name) {
        return null;
    }
    const clName = parseInt(class_name.toLowerCase());
    if (Number.isNaN(clName)) {
        return null;
    }
    for (const cl of classNameOptions) {
        if (cl.value === clName) {
            return clName;
        }
    }
    return null;
};

export const getProfileTypeValue = (profileType: string) => {
    if (!profileType) {
        return null;
    }
    const pType = profileType.toLowerCase();
    for (const p of profileTypeOptions) {
        if (p.label.toLowerCase() === pType) {
            return p.value;
        }
    }
    return null;
};

export const getValidPhoneNumber = (mobile: string) => {
    if (!mobile) {
        return null;
    }
    const ph = parseInt(mobile.toLowerCase());
    if (Number.isNaN(ph)) {
        return null;
    }
    mobile = String(ph);
    if (mobile.length !== 10) {
        return null;
    }
    return mobile;
};

export const getDecodedUriString = (enc: string) => {
    if (!enc) {
        return "";
    }
    return decodeURIComponent(enc);
};
const arr = (x) => Array.from(x);
const num = (x) => Number(x) || 0;
const str = (x) => String(x);
const isEmpty = (xs) => xs.length === 0;
const take = (n) => (xs) => xs.slice(0, n);
const drop = (n) => (xs) => xs.slice(n);
const reverse = (xs) => xs.slice(0).reverse();
const comp = (f) => (g) => (x) => f(g(x));
const not = (x) => !x;
const chunk = (n) => (xs) =>
    isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

export const numToWords = (n: any): string => {
    const a = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
    ];
    const b = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
    ];
    const g = [
        "",
        "thousand",
        "million",
        "billion",
        "trillion",
        "quadrillion",
        "quintillion",
        "sextillion",
        "septillion",
        "octillion",
        "nonillion",
    ];
    // this part is really nasty still
    // it might edit this again later to show how Monoids could fix this up
    const makeGroup = ([ones, tens, huns]) => {
        return [
            num(huns) === 0 ? "" : a[huns] + " hundred ",
            num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + " ") || "",
            a[tens + ones] || a[ones],
        ].join("");
    };
    // "thousands" constructor; no real good names for this, i guess
    const thousand = (group, i) =>
        group === "" ? group : ` ${group} ${g[i]} `;
    // execute !
    if (typeof n === "number") return numToWords(String(n));
    if (n === "0") return "zero";
    return comp(chunk(3))(reverse)(arr(n))
        .map(makeGroup)
        .map(thousand)
        .filter(comp(not)(isEmpty))
        .reverse()
        .join(" ");
};

export const isMobile = () => {
    if (typeof navigator === "undefined") return false;
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

export const getTouchAction = () => {
    if (isMobile()) return "manipulation";
    return "none";
};

export function isIOS() {
    if (typeof navigator === "undefined") return false;
    return (
        [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
}
export function isIOSVersionAtLeast13_4() {
    if (!isIOS()) return true; // If not iOS, return true as per instructions

    const userAgent = navigator.userAgent;
    const match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);

    if (match) {
        const major = parseInt(match[1], 10);
        const minor = parseInt(match[2], 10);

        // Check if version is at least 13.4
        if (major > 13 || (major === 13 && minor >= 4)) {
            return true;
        }
        return false;
    }
    console.log("can't determine the iOS version");
    // If we can't determine the version, we'll return true to be safe
    return true;
}

export function isIPad() {
    if (typeof navigator === "undefined") return false;
    return ["iPad Simulator", "iPad", "iPad Pro", "MacIntel"].includes(
        navigator.platform,
    );
}

export function interpolate(t: any, c: any) {
    return t.replace(
        /\${([^}]+)}/g,
        (m: any, p: any) =>
            p
                .split(".")
                .reduce((a: any, f: any) => (a ? a[f] : undefined), c) ?? m,
    );
}

export function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1) || "";
}

export function convertNumberToWords(text) {
    const splitText = text.split(/(\d+)/g);
    const getSplit = [];
    for (let i = 0; i < splitText.length; i++) {
        if (!isNaN(splitText[i])) {
            getSplit.push(numToWords(splitText[i]));
        } else {
            getSplit.push(splitText[i]);
        }
    }
    return getSplit.join("");
}

export function checkTextHasLocalCurrency(text) {
    if (
        text.indexOf("lakh") > -1 ||
        text.indexOf("crore") > -1 ||
        text.indexOf("lacs") > -1
    ) {
        return true;
    }
    return false;
}

export function numToWords2(amount) {
    const words = [];
    words[0] = "";
    words[1] = "one";
    words[2] = "two";
    words[3] = "three";
    words[4] = "four";
    words[5] = "five";
    words[6] = "six";
    words[7] = "seven";
    words[8] = "eight";
    words[9] = "nine";
    words[10] = "ten";
    words[11] = "eleven";
    words[12] = "twelve";
    words[13] = "thirteen";
    words[14] = "fourteen";
    words[15] = "fifteen";
    words[16] = "sixteen";
    words[17] = "seventeen";
    words[18] = "eighteen";
    words[19] = "nineteen";
    words[20] = "twenty";
    words[30] = "thirty";
    words[40] = "forty";
    words[50] = "fifty";
    words[60] = "sixty";
    words[70] = "seventy";
    words[80] = "eighty";
    words[90] = "ninety";
    amount = amount.toString();
    const atemp = amount.split(".");
    const number = atemp[0].split(",").join("");
    const n_length = number.length;
    let words_string = "";
    if (n_length <= 9) {
        const n_array = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        const received_n_array = [];
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (n_array[i] == 1) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                }
            }
        }
        let value = "";
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if (
                (i == 1 && value != 0) ||
                (i == 0 && value != 0 && n_array[i + 1] == 0)
            ) {
                words_string += "crore ";
            }
            if (
                (i == 3 && value != 0) ||
                (i == 2 && value != 0 && n_array[i + 1] == 0)
            ) {
                words_string += "lakh ";
            }
            if (
                (i == 5 && value != 0) ||
                (i == 4 && value != 0 && n_array[i + 1] == 0)
            ) {
                words_string += "thousand ";
            }
            if (
                i == 6 &&
                value != 0 &&
                n_array[i + 1] != 0 &&
                n_array[i + 2] != 0
            ) {
                words_string += "hundred ";
            } else if (i == 6 && value != 0) {
                words_string += "hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string;
}
export const numberWords = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
    "hundred",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
    "crore",
    "lakh",
];

export function groupByAwait(arr) {
    const result = [];
    let currentGroup = [];
    arr.forEach((item) => {
        if (item.config.await) {
            if (currentGroup.length >= 0) {
                currentGroup.push(item);
                result.push(currentGroup);
                currentGroup = [];
            }
        } else {
            currentGroup.push(item);
        }
    });
    if (currentGroup.length > 0) {
        result.push(currentGroup);
    }
    return result;
}

export const getUserId = () => {
    const user = getStudentFromStorage();
    if (!user) return undefined;
    const parsedUser = JSON.parse(user);
    return parsedUser?.id;
};

export const useInsertLog = () => {
    const [insertLogMutation, { error }] = useMutation(INSERT_LOG);
    async function insertLog(data: any) {
        return;
        const user_id = getUserId();
        if (!user_id) return;
        await insertLogMutation({
            variables: {
                user_id,
                data: JSON.stringify(data),
            },
        });

        if (error) {
            console.log("Insert Log Err", error);
        }
    }

    return { insertLog };
};

export const DYNAMIC_TEXT_REGEX = /@@(.*?)@@/g;

export function withTimeout<T>(promise: Promise<T>, timeout: number) {
    let timeoutId: any;
    const timeoutPromise = new Promise((resolve, reject) => {
        timeoutId = setTimeout(() => {
            resolve("Timeout reached");
        }, timeout * 1000);
    });
    return Promise.race([
        promise.finally(() => clearTimeout(timeoutId)),
        timeoutPromise,
    ]);
}

export function ensureCallbackTriggered(
    func,
    callback = () => {},
    time = 5000,
) {
    let callbackTriggered = false;
    let interval: any = null;
    function wrappedCallback() {
        if (!callbackTriggered) {
            callbackTriggered = true;
            interval && clearTimeout(interval);
            callback();
        }
    }
    func?.(wrappedCallback);
    interval = setTimeout(() => {
        if (!callbackTriggered) {
            wrappedCallback();
        }
    }, time);
}

export const isVisualViewport = () =>
    typeof window !== "undefined" && "visualViewport" in window;
