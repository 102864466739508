import Head from "next/head";
import React from "react";

const MetaHead = ({ metadataTags }: any) => {
    const canonicalURL =
        process.env.NEXT_PUBLIC_CANONICAL_URL || "https://mathai.ai";
    return (
        <Head>
            <link rel="canonical" href={canonicalURL} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no"
            ></meta>
            <meta name="interactive-widget" content="resizes-content"></meta>

            {/* <link
            href="https://cdn.jsdelivr.net/npm/katex@0.15.3/dist/katex.min.css"
            rel="stylesheet"
        /> */}
            {/* <Script
            src="https://cdn.jsdelivr.net/npm/katex@0.15.3/dist/katex.min.css"
            strategy="afterInteractive"
            async
        /> */}
            {/* <script src="https://cdn.tailwindcss.com"></script> */}
            <link
                href="https://fonts.googleapis.com/css2?family=Manrope&family=Open+Sans&family=Lobster:wght@400;500&family=Cedarville+Cursive:wght@400;500&family=Bilbo&family=Epilogue:wght@100;200;300;400;500;600;700;800;900&family=Inter:wght@300;400&display=swap"
                rel="stylesheet"
            />

            <title>
                {metadataTags?.title || "MathAI: World’s 1st AI Math Coach"}
            </title>

            <meta
                property="og:site_name"
                content="MathAI: World’s 1st AI Math Coach"
            />
            <meta
                property="og:title"
                content={
                    metadataTags?.title || "MathAI: World’s 1st AI Math Coach"
                }
            />
            <meta
                property="og:description"
                content={
                    metadataTags?.description ||
                    "MathAI: World’s 1st AI Math Coach"
                }
            />
            <meta property="og:image" content={metadataTags?.image || ""} />
            <link
                rel="preconnect"
                href={`${process.env.NEXT_PUBLIC_MONOLITH_ENDPOINT}`}
            />
            <link
                rel="preconnect"
                href={`${process.env.NEXT_PUBLIC_API_ENDPOINT}`}
            />
            {/* <link rel="preconnect" href="https://o503779.ingest.sentry.io" />
        <link rel="preconnect" href="https://cdn.mxpnl.com" /> */}
        </Head>
    );
};

export default MetaHead;
