import { Capacitor } from "@capacitor/core";

export function isIOS() {
    if (typeof navigator === "undefined") return false;
    return (
        [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
}

export function isIOSVersionAtLeast13_4() {
    if (!isIOS()) return true; // If not iOS, return true as per instructions

    const userAgent = navigator.userAgent;
    const match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);

    if (match) {
        const major = parseInt(match[1], 10);
        const minor = parseInt(match[2], 10);

        // Check if version is at least 13.4
        if (major > 13 || (major === 13 && minor >= 4)) {
            return true;
        }
        return false;
    }
    console.log("can't determine the iOS version");
    // If we can't determine the version, we'll return true to be safe
    return true;
}

export function isNativeApp() {
    if (Capacitor.getPlatform() !== "web") return true;
    return false;
}
export function isISOApp() {
    if (Capacitor.getPlatform() == "ios") return true;
    return false;
}
export function isApp() {
    if (Capacitor.getPlatform() == "android") return true;
    return false;
}
export function isSafari() {
    if (typeof window == "undefined") return false;
    var userAgent = navigator.userAgent;
    // Check if it's Safari by ensuring "Safari" is in the userAgent but not "Chrome"
    return userAgent.includes("Safari") && !userAgent.includes("Chrome");
}
export function isChrome() {
    if (typeof window == "undefined") return false;
    var userAgent = navigator.userAgent;
    // Check if it's Chrome by ensuring "Chrome" is in the userAgent
    return userAgent.includes("Chrome");
}
